import { Vertical } from '@sbt-web/adv';
import { useMemo } from 'react';
import { macroFromCategoryId } from '@shared/helpers/Themes';
import { fromMacroCategoryToVertical } from '@client/components/Search/Containers/SmartAdvItem/helpers';
import { CategoryId } from '@sbt-web/networking';

export const useGetVerticalByCategoryId = (
  categoryId: CategoryId
): Vertical => {
  return useMemo(() => {
    const macroCategory = macroFromCategoryId(categoryId);
    const macroCategoryName = macroCategory.friendly;
    return fromMacroCategoryToVertical(macroCategoryName);
  }, [categoryId]);
};
