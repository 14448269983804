import { ADS_PER_PAGE } from '@shared/constants';
import { ParsedFilters } from '@tools/tracking/getAvailableFiltersHelper';

interface BaseSearch {
  '@id': string;
  '@type': 'SearchMetadata';
  source: 'MainSearch';
}

interface GenericSearchFilters {
  label: string;
  values?: string[];
  '@type': 'GenericSearchFilter';
  locations?: ParsedFilters['locations'];
  minValue?: string;
  maxValue?: string;
}

type ListingSearch = BaseSearch & {
  maxRank: number;
  rank?: number; // field included only in the Geoboost events
  offset: number;
  display: 'List';
  filterOrigin?: string;
  genericSearchFilters: GenericSearchFilters[] | null;
};

type DetailSearch = BaseSearch & {
  rank: number;
};

export function getSearchGenericFilters(
  availableFilters: ParsedFilters[]
): GenericSearchFilters[] {
  return availableFilters?.map((filter) => {
    switch (filter?.item?.type) {
      case 'locations':
        return {
          label: filter.item.uri,
          locations: filter.locations,
          values: filter.values,
          '@type': 'GenericSearchFilter',
        };
      case 'range':
        return {
          label: filter.item.uri.replace('/', '').replace(/\/|[_]/g, '-'),
          values: [
            filter.minValue ? filter.minValue : '-',
            filter.maxValue ? filter.maxValue : '-',
          ],
          '@type': 'GenericSearchFilter',
        };
      default:
        return {
          label: filter.item.uri?.replace('/', '').replace(/\/|[_]/g, '-'),
          values: filter.values,
          '@type': 'GenericSearchFilter',
        };
    }
  });
}

export default function transform(
  rawSearch: { page: number },
  rawAds: Array<unknown>,
  id?: string | null,
  filterOrigin?: string,
  availableFilters?: ParsedFilters[]
): ListingSearch | null {
  if (!id) {
    return null;
  }

  const multiplier = rawSearch.page > 0 ? rawSearch.page - 1 : 0;
  return {
    '@id': id,
    '@type': 'SearchMetadata',
    source: 'MainSearch',
    maxRank: multiplier * ADS_PER_PAGE + rawAds.length - 1,
    offset: multiplier * ADS_PER_PAGE,
    display: 'List',
    filterOrigin,
    genericSearchFilters:
      availableFilters !== undefined
        ? getSearchGenericFilters(availableFilters)
        : null,
  };
}

export function buildSearchWithRank(id: string, rank: number): DetailSearch {
  return {
    '@id': id,
    '@type': 'SearchMetadata',
    source: 'MainSearch',
    rank,
  };
}
